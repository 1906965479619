<template>
  <TitleCtaContainer :cta="ctaLink" :subtitle :title>
    <RevCardCarousel
      :desktop-card-width="carouselWidth"
      :next-alternative-text="altText.next"
      :prev-alternative-text="altText.prev"
    >
      <ModularCard
        v-for="(card, index) of cards"
        :key="card.id"
        :background="card.background"
        :blockPosition
        :card-position="index + 1"
        class="h-full"
        :sections="card.sections"
        :size="size"
        :with-padding="cardsHavePadding"
      />
    </RevCardCarousel>
  </TitleCtaContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type ModularCardsCarouselProps } from '@backmarket/http-api/src/api-specs-content/models/modular-card'
import TitleCtaContainer from '@backmarket/nuxt-layer-cms/TitleCtaContainer.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCardCarousel } from '@ds/components/CardCarousel'

import translations from '../../common/translations'

import ModularCard from './ModularCard.vue'

const props = withDefaults(
  defineProps<Omit<ModularCardsCarouselProps, 'layout'> & ContentBlockProps>(),
  {
    cardsHavePadding: true,
  },
)

const i18n = useI18n()

const altText = {
  prev: i18n(translations.alternativePrevious),
  next: i18n(translations.alternativeNext),
}
const carouselWidth = computed(() =>
  props.size === 'medium' ? 'wide' : 'standard',
)
</script>
